<template>
  <v-container class="container-card">
    <FiltroRelatorios @selectedFilters="filters = $event" />
    <Relatorios :filters="filters" :list="menus" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FiltroRelatorios from "@/components/comissao/relatorios/FiltroRelatorios";
import Relatorios from "@/components/comissao/relatorios/Relatorios";
export default {
  components: {
    FiltroRelatorios,
    Relatorios
  },

  data() {
    return {
      filters: {},
      menus: [
        {
          title: "Relatórios",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Comissão",
              service: "comissaoEquipe",
              permission: 1043
            }
          ]
        },
        // {
        //   title: "Supervisor Consórcio",
        //   icon: "mdi-file-outline",
        //   reportList: [
        //     {
        //       text: "Relatório Comissão Supervisor Consórcio",
        //       service: "comissaoSupervisorGeral",
        //       permission: 985
        //     }
        //   ]
        // },
        {
          title: "Diversos",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Colaboradores Equipe",
              service: "colaboradorEquipeResumo",
              permission: 1045
            },
            {
              text: "Relatório Resumo Equipe",
              service: "resumoEquipe",
              permission: 1005
            },
            {
              text: "Relatório Resumo Equipe Ponto Venda Anual",
              service: "resumoEquipeAno",
              permission: 1006
            },
            {
              text: "Relatório de Veiculos Entrega",
              service: "entregaEquipe",
              permission: 1044
            },
            {
              text: "Relatório de Cálculo Metas Supervisores",
              service: "funcaoMeta",
              permission: 1026
            },
            {
              text: "Relatório Inad/Adimplência Cotas Equipes",
              service: "inadiplenciaCotaEquipe",
              permission: 1025
            },
            {
              text: "Relatório Movimentação",
              service: "colaboradorHistorico",
              permission: 1041
            }
          ]
        }
      ]
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Relatórios Supervisor/Equipe");
  }
};
</script>

<style></style>
